import './LinkedResource.css';

export default function LinkedResource({ url, title, description }) {
  return (
    <div className="linked-resource">
      <a className="external-link" href={ url } target="_blank" rel="noreferrer noopener">{ title }</a>
      <p className="linked-resource-description">{ description }</p>
    </div>
  );
}
