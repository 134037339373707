import LinkedResource from "../components/LinkedResource/LinkedResource";
import Header from "../components/Header/Header";
import Notes from "../components/Notes";

const blogPosts = [
  {
    title: "Interview with Fredrikstad Blad",
    url: "https://www.f-b.no/simon-27-skal-studere-pa-oxford-hadde-ikke-hatt-muligheten-uten-dette/s/5-59-3315771",
    synopsis: "Interview with Fredrikstad Blad on the occasion of my receiving the Aker Scholarship 2024, securing full funding for further graduate studies at Oxford."
  },
  {
    title: "Behind NRKort: Using AI to Summarize the News",
    url: "https://www.bekk.christmas/post/2023/24/behind-nrkort-using-ai-to-summarize-the-news",
    // synopsis: "Blog post for Bekk's annual Christmas calendar about NRKort, an AI service summarizing the news from the main Norwegian news provider NRK."
    synopsis: "Blog post about NRKort on Bekk Christmas, Bekk's annual Christmas calendar."
  },
  {
    title: 'Smoothing Noisy Signals Using Discrete Mollifier Transforms – Part 2',
    url: 'https://www.bekk.christmas/post/2022/14/smoothing-out-noisy-signals-using-discrete-mollifier-transforms-part-2',
    synopsis: 'Technical exposition of a family of discrete smoothing operators and their implementation.'
  },
  {
    title: 'Smoothing Noisy Signals Using Discrete Mollifier Transforms – Part 1',
    url: 'https://www.bekk.christmas/post/2022/12/smoothing-out-noisy-signals-using-discrete-mollifier-transforms',
    synopsis: 'Introduces discrete smoothing operators for noise reduction in time series data, with an emphasis on how this pertains to the detection of QRS complexes in electrocardiographic signals.'
  }
];

export default function Home() {
  return (
    <>
      <Header />
      <main>
        <section className="content-sections">
          <Notes />
          <section>
            <h2>External</h2>
            <ul>
              {
                blogPosts.map(({ title, synopsis, url }) => {
                  return (
                    <li>
                      <LinkedResource url={ url } title={ title } description={ synopsis } />
                    </li>
                  );
                })
              }
            </ul>
          </section>
          <section>
            <h2>Other Projects</h2>
            <ul>
              <li>
                <LinkedResource
                  url={ "https://www.bekk.christmas/post/2023/24/behind-nrkort-using-ai-to-summarize-the-news" }
                  title="NRKort (Discontinued)"
                  description="AI service summarising the news from the main Norwegian news provider (NRK). Aspires to make the consumption of news more effective, hence everyone more productive. First service of its kind in Norway, to the best of my knowledge."
                />
              </li>
            </ul>
          </section>
        </section>
      </main>
    </>
  );
}
