import './NoPage.css';
import TeleportHomeButton from '../../components/TeleportHomeButton/TeleportHomeButton';

export default function NoPage() {
  return (
    <header className="no-page-header">
      <h1>Simon Foldvik</h1>
      <h2>There doesn't appear to be anything here. 👀</h2>
      <p>Maybe the resource you are looking for has been moved?</p>
      <TeleportHomeButton />
    </header>
  );
}
