import './TeleportHomeButton.css';

export default function TeleportHomeButton() {
  function teleportHome() {
    window.location.href = '/';
  }
  return (
    <button className="teleport-home-button" onClick={ teleportHome }>
      <i className="fas fa-arrow-left"/> Teleport home
    </button>
  );
}
