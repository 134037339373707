import './Header.css';

export default function Header({ messageOfTheDay }) {
  return (
    <header>
      <h1>Simon Foldvik</h1>
      <p>🔜 Next up: MSc in Mathematical Sciences, University of Oxford 2024-2025</p>
      <p>👨🏼‍🎓 Balliol College, University of Oxford</p>
      <p>✉️ Contact: simonfoldvik-at-protonmail-dot-com</p>
      <section className="short-cv">
        <h2>Short CV</h2>
        <ul>
          <li>MSc in Mathematical Sciences (University of Oxford, 2024-2025)</li>
          <li>Aker Scholar 2024: Full funding for studies at Oxford</li>
          <li>Senior Software Engineer (Bekk Consulting)</li>
          <li>Research Internship (Simula Research Laboratories)</li>
          <li>Graduate Teaching Assistant in Mathematics (University of Oslo)</li>
          <li>MSc in Mathematics (University of Oslo)</li>
          <li>BSc in Mathematics and Computer Science (University of Oslo)</li>
        </ul>
      </section>
    </header>
  );
}
